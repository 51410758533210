<script>
/**
 * Management of project milestones in a project (LOCAL CONTEXT)
 */
import ProjectService from "@/services/ProjectService";
import Cloner from "@/libraries/Cloner";
import VoucherService from "@/services/VoucherService";
import VMAllocations from "@/components/Costs/VMAllocations";
import VBookedVoucherModal from "@/components/Costs/VBookedVoucherModal";
import VAllocateModal from "@/components/Costs/VAllocateModal";
import VPdfModal from "@/components/Costs/VPdfModal";
import VFilters from "@/components/Search/VFilters";
import VEcConnection from "@/components/Costs/VEcConnection";

export default {
    name: "VCosts",
    props: {
        project_id: {}
    },
    components: {
        VMAllocations,
        VBookedVoucherModal,
        VAllocateModal,
        VPdfModal,
        VFilters,
        VEcConnection
    },
    data() {
        return {
            loading: true,
            vouchers: [],
            meta: {},
            vContext: null,
            allocateContext: null,
            showDetailsModal: false,
            showAllocateModal: false,
            project: {},
            showDocModal: false,
            docContext: null,
            allocateProjectId: null,
            allocateProject: null,
            bookLoading: 0
        };
    },
    async created() {
        this.initSearch();
        await this.fetchData();
        this.loading = false;
        this.$apiEnableWatcher();
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                params["project_id"] = this.project_id;
                params["functionView"] = "index";
                params["includes"] = "project";
                params["status"] = "10";
                const { data } = await VoucherService.GET(params);
                this.vouchers = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("fd e", e);
            }
        },
        /**
         * Fetch the project + customer
         */
        async fetchProject() {
            try {
                const r = await ProjectService.GETS(this.allocateProjectId);
                this.allocateProject = r.data.data;
            } catch (e) {
                console.log("fetchProject exception", e);
            }
        },
        /**
         * Show details in a modal`
         * @param ctx
         */
        showDetails(ctx) {
            this.vContext = ctx;
            this.showDetailsModal = true;
        },
        async editAllocation(voucher) {
            this.bookLoading = voucher.project_id;
            try {
                this.allocateProjectId = voucher.project_id;
                await this.fetchProject();
            } catch (e) {
                console.log("alloc e", e);
            }

            this.allocateContext = voucher;
            this.showAllocateModal = true;
            this.bookLoading = 0;
        },
        showVoucherDoc(v) {
            this.showDocModal = true;
            this.docContext = v;
        },
        initSearch() {
            this.apiSearchFilter = {
                search: ""
            };
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag>
                <base-button
                    type="secondary"
                    size="sm"
                    @click="setContext({ empty: true })"
                    >{{ $t("travel_prices.add_new") }}</base-button
                >
            </bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-filters
                        :enable-filters="false"
                        v-model="apiSearchFilter.search"
                        :search="true"
                        :search-placeholder="
                            $t('vouchers.placeholder_search_in_vouchers')
                        "
                        @reset-search="initSearch"
                    />

                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="vouchers.length > 0">
                            <v-table class="table table-mg table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                @sort="$handleSorting($event)"
                                                name="Projects.title"
                                                >{{
                                                    $t(
                                                        "vouchers.tbl_hdr_project"
                                                    )
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @click="handleSorting($event)"
                                                >{{
                                                    $t("vouchers.hdr_tbl_date")
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @click="handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "vouchers.hdr_tbl_description"
                                                    )
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-right"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @click="handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "vouchers.hdr_tbl_amount"
                                                    )
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th class="text-center"
                                            ><v-sorter
                                                :paginator="apiPaginator"
                                                name=""
                                                @click="handleSorting($event)"
                                                >{{
                                                    $t(
                                                        "vouchers.hdr_tbl_milestones"
                                                    )
                                                }}</v-sorter
                                            ></v-th
                                        >
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="v in vouchers" :key="v.id">
                                        <v-td
                                            ><router-link
                                                :to="{
                                                    name: 'ProjectViewCosts',
                                                    params: {
                                                        project_id: v.project_id
                                                    }
                                                }"
                                                >{{
                                                    v.project.title
                                                }}</router-link
                                            >
                                            <div class="small text-muted">
                                                <v-ec-connection :context="v" />
                                            </div></v-td
                                        >
                                        <v-td>{{
                                            $d(
                                                new Date(v.voucher_date),
                                                "short"
                                            )
                                        }}</v-td>
                                        <v-td>{{ v.description }} </v-td>
                                        <v-td class="text-right">{{
                                            $n(v.amount, {
                                                style: "currency",
                                                currency: v.currency,
                                                currencyDisplay: "code"
                                            })
                                        }}</v-td>

                                        <v-td class="text-center"
                                            ><v-m-allocations
                                                :context="
                                                    v.milestone_allocations
                                                "
                                        /></v-td>
                                        <v-td class="text-right">
                                            <base-button
                                                size="sm"
                                                outline
                                                type="primary"
                                                @click="showDetails(v)"
                                            >
                                                <i
                                                    class="far fa-search-plus fa-lg fa-fw m-click"
                                                ></i
                                            ></base-button>

                                            <base-button
                                                size="sm"
                                                outline
                                                type="primary"
                                                @click="showVoucherDoc(v)"
                                            >
                                                <i
                                                    class="far fa-file-pdf fa-lg fa-fw m-click"
                                                ></i
                                            ></base-button>

                                            <base-button
                                                size="sm"
                                                outline
                                                type="primary"
                                                @click="editAllocation(v)"
                                            >
                                                <i
                                                    class="far fa-pencil fa-lg fa-fw m-click"
                                                ></i
                                            ></base-button>
                                        </v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                            <pre v-if="false">{{ vouchers }}</pre>
                        </template>
                        <div class="mx-4 alert alert-warning" v-else>
                            {{ $t("vouchers.no_vouchers_found") }}
                        </div>
                    </card>
                </div>
            </div>
            <portal to="modals">
                <v-booked-voucher-modal
                    :context="vContext"
                    @close="showDetailsModal = false"
                    v-if="showDetailsModal"
                />
                <v-allocate-modal
                    :context="allocateContext"
                    :project-id="allocateProjectId"
                    :project="allocateProject"
                    edit
                    v-if="showAllocateModal"
                    @close="showAllocateModal = false"
                />
                <v-pdf-modal
                    v-if="showDocModal"
                    :context="docContext"
                    @close="showDocModal = false"
                />
            </portal>
        </div>
    </div>
</template>

<style lang="scss"></style>

<script>
import MilestoneAllocationService from "@/services/MilestoneAllocationService";

export default {
    props: {},
    data() {
        return {
            loading: true,
            data: [],
            hashed: {},
            meta: {}
        };
    },
    async created() {
        this.loading = true;
        await this.fetchData();
        this.loading = false;
    },
    methods: {
        async fetchData() {
            try {
                let params = this.$buildParams();
                const { data } = await MilestoneAllocationService.GET_report(
                    params
                );
                this.data = data.data;
                this.data.forEach(elem => {
                    if (!(elem.project_id in this.hashed)) {
                        this.hashed[elem.project_id] = {};
                    }

                    if (
                        !(
                            elem.project_milestone_id in
                            this.hashed[elem.project_id]
                        )
                    ) {
                        this.hashed[elem.project_id][
                            elem.project_milestone_id
                        ] = [];
                    }

                    this.hashed[elem.project_id][
                        elem.project_milestone_id
                    ].push(elem);
                });

                this.meta = data.meta;
            } catch (e) {
                console.log("f.e.", e);
            }
        }
    }
};
</script>

<template>
    <div>
        <base-header class="pb-1" type="">
            <bread-bag> </bread-bag>
        </base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <v-loader :loading="loading" v-if="loading" />
                    <template v-else>
                        <pre v-if="false">{{ hashed }}</pre>
                        <pre v-if="false">{{ data }}</pre>

                        <card
                            body-classes=""
                            v-for="(p, project_id) in meta.projects"
                            :key="project_id"
                        >
                            <h2 class="mb-0">{{ p.project_title }}</h2>
                            <div class="mb-4 small text-muted">
                                #{{ project_id }}
                            </div>

                            <div v-for="(m, k) in meta.milestones[project_id]">
                                <div class="row">
                                    <div class="col">
                                        <h4>
                                            M{{ m.m_ident }}
                                            {{ m.milestone_name }}
                                        </h4>
                                        <div>
                                            {{
                                                $t(
                                                    "vouchers.total_in_this_milestone"
                                                )
                                            }}: {{ m.amount_milestone }}
                                            {{ m.currency }}
                                        </div>
                                    </div>
                                    <div class="col">
                                        <v-table
                                            class="table table-sm table-mg table-voucher-report"
                                        >
                                            <v-thead>
                                                <v-tr>
                                                    <v-th>{{
                                                        $t(
                                                            "vouchers.tbl_hdr_category"
                                                        )
                                                    }}</v-th>
                                                    <v-th class="text-right">{{
                                                        $t(
                                                            "vouchers.tbl_hdr_amount"
                                                        )
                                                    }}</v-th>
                                                </v-tr>
                                            </v-thead>
                                            <v-tbody>
                                                <v-tr
                                                    v-for="entr in hashed[
                                                        project_id
                                                    ][m.project_milestone_id]"
                                                >
                                                    <v-td>{{
                                                        entr.category_name
                                                    }}</v-td>
                                                    <v-td class="text-right"
                                                        >{{ entr.amount }}
                                                        {{
                                                            entr.currency
                                                        }}</v-td
                                                    >
                                                </v-tr>
                                                <v-tr v-if="false">
                                                    <v-td>SAMLET</v-td>
                                                    <v-td>{{ m }}</v-td>
                                                    <v-td>
                                                        {{
                                                            hashed[p.project_id]
                                                        }}
                                                    </v-td>
                                                </v-tr>
                                            </v-tbody>
                                        </v-table>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        </card>

                        <pre v-if="false">{{ meta }}</pre>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.table-voucher-report {
    width: 700px;
}
</style>

<script>
import ProjectMilestoneService from "@/services/ProjectMilestoneService";
import VoucherCategoryService from "@/services/VoucherCategoryService";
import MilestoneAllocationService from "@/services/MilestoneAllocationService";
import Big from "big.js";
import VCostInput from "./VCostInput";

export default {
    props: {
        context: {},
        projectId: {},
        project: {},
        edit: {
            type: Boolean,
            default: false
        }
    },
    components: { VCostInput },
    data() {
        return {
            milestones: [],
            categories: [],
            loading: true,
            entity: {},
            test: 0,
            milestoneSelect: "",
            sumMilestones: 0,
            totalRemaining: 0
        };
    },
    computed: {
        /**
         * Data is "columnized" here so that it's easier to display it w/o being
         * forced to establish the height of the container
         */
        columnizedCategories() {
            const numColumns = 3;

            const parts = [];
            const total = this.categories.length;
            const partSize = Math.ceil(total / numColumns);

            for (let i = 0; i < numColumns; i++) {
                const start = i * partSize;
                const end = start + partSize;
                parts.push(this.categories.slice(start, end));
            }
            return parts;
        },
        // Whether the entry is balanced (all money allocated)
        isBalanced() {
            if (this.totalRemaining != 0) {
                return false;
            }

            if (Object.keys(this.entity).length == 0) {
                return false;
            }

            let ins = true;
            Object.keys(this.entity).forEach(m_id => {
                if (this.entity[m_id].remaining_amount != 0) {
                    ins = false;
                }
                // console.log("M===> ", m_id, this.entity[m_id].remaining_amount);
            });

            return ins;
        },
        // Whether too much is being allocated
        hasGeneralError() {
            if (this.totalRemaining < 0) {
                return true;
            } else {
                return false;
            }
        }
    },
    async created() {
        await this.fetchMilestones();
        await this.fetchCategories();
        if (this.edit) {
            await this.fetchEditingData();
        }
        this.recalculateTotalRemaining();
        this.loading = false;
    },
    methods: {
        /**
         * Fetch backend data and make sure it's inserted into the model
         */
        async fetchEditingData() {
            try {
                const {
                    data
                } = await MilestoneAllocationService.GET_fetchForEditing(
                    this.context.id
                );
                Object.keys(data.data).forEach(mid => {
                    this.$set(this.entity, mid, data["data"][mid]);
                });

                console.log("Fetched", data.data, Object.keys(data.data));
            } catch (e) {
                console.log("f edit, e", e);
            }
        },
        /**
         * Save form - create or update
         */
        async saveForm() {
            try {
                if (this.edit) {
                    await MilestoneAllocationService.PUT_update(
                        this.context.id,
                        this.entity
                    );
                } else {
                    await MilestoneAllocationService.POST_create(
                        this.context.id,
                        this.entity
                    );
                }

                this.$emit("close-reload");
            } catch (e) {
                console.log("saveForm e", e);
            }
        },
        /**
         * Create a milestone allocation group
         * @param milestone
         */
        addMilestoneAllocation(milestone) {
            if (milestone.id in this.entity) {
                return;
            }

            this.$set(this.entity, milestone.id, {
                amount: 0,
                sum_categories: 0,
                remaining_amount: 0,
                project_milestone: milestone,
                project_id: milestone.project_id,
                currency: this.context.currency,
                voucher_id: this.context.id,
                category_allocations: this.getAllocations()
            });
        },
        /**
         * Get allocations for creating the v-model entity
         */
        getAllocations() {
            let allocs = {};

            this.categories.forEach(c => {
                allocs[c.value] = 0;
            });

            return allocs;
        },
        /**
         * Fetch voucher categories -> dropdown
         */
        async fetchCategories() {
            try {
                const {
                    data
                } = await VoucherCategoryService.GET_extendedDropdown();
                this.categories = data.data;
            } catch (e) {
                console.log("f c e", e);
            }
        },
        /**
         * Fetch project milestones -> dropdown
         */
        async fetchMilestones() {
            try {
                const { data } = await ProjectMilestoneService.GET({
                    project_id: this.projectId
                });
                this.milestones = data.data;
            } catch (e) {
                console.log("fm ,e", e);
            }
        },
        /**
         * Zero all v-model categories within a given project_milestone
         * @param pm_id
         */
        zeroCats(pm_id) {
            Object.keys(this.entity[pm_id]["category_allocations"]).forEach(
                k => {
                    this.entity[pm_id]["category_allocations"][k] = 0;
                }
            );

            this.recalculateGroup(pm_id);
        },
        recalculateGroup(pm_id) {
            let sum = new Big(0);
            console.log("recalculateGroup = ", pm_id, this.entity);

            Object.keys(this.entity[pm_id]["category_allocations"]).forEach(
                k => {
                    console.log(
                        "=> PLUS",
                        this.entity[pm_id]["category_allocations"][k]
                    );
                    sum = sum.plus(
                        this.entity[pm_id]["category_allocations"][k] != ""
                            ? this.entity[pm_id]["category_allocations"][k]
                            : 0
                    );
                }
            );

            this.entity[pm_id]["sum_categories"] = sum.toNumber();
            const total = this.entity[pm_id]["amount"];
            this.entity[pm_id]["remaining_amount"] = Big(total)
                .minus(sum)
                .toNumber();
            this.recalculateTotalRemaining();
        },
        /**
         * Recalculate the total remaining (among all milestones)
         */
        recalculateTotalRemaining() {
            let gsum = Big(0);
            Object.keys(this.entity).forEach(k => {
                gsum = gsum.plus(this.entity[k]["amount"]);
            });

            gsum = Big(this.context.amount).minus(gsum);

            this.totalRemaining = gsum.toNumber();
        },
        /**
         * Remove a milestone from the calculation
         * @param id
         */
        removeElement(id) {
            this.$swal({
                icon: "warning",
                text: this.$t("vouchers.delete_are_you_sure"),
                showConfirmButton: true,
                showCancelButton: true
            }).then(async confirmed => {
                if (confirmed.isConfirmed) {
                    this.$delete(this.entity, id);
                    this.recalculateTotalRemaining();
                }
            });
        }
    }
};
</script>

<template>
    <modal
        size="full"
        :show="true"
        @close="$emit('close')"
        class="budget-modal"
        :loading="loading"
    >
        <template slot="sticky">
            <div class="row">
                <div class="col text-bold">
                    {{ $t("vouchers.amount") }}:
                    {{
                        $n(context.amount, {
                            style: "currency",
                            currency: context.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </div>
                <div class="col d-flex justify-content-around">
                    <div
                        class="px-2 rounded"
                        :class="{
                            'text-primary': !hasGeneralError,
                            'bg-danger text-white': hasGeneralError
                        }"
                    >
                        <template v-if="hasGeneralError">
                            <i class="far fa-exclamation-triangle fa-fw"></i>
                            {{ $t("vouchers.error") }}
                        </template>
                        <template v-else>
                            <i class="far fa-check fa-fw"></i>
                            {{ $t("vouchers.no_errors") }}
                        </template>
                    </div>

                    <div
                        class="px-2 rounded"
                        :class="{
                            'text-primary': isBalanced,
                            'bg-danger text-white': !isBalanced
                        }"
                    >
                        <template v-if="isBalanced">
                            <i class="far fa-equals fa-fw"></i>
                            {{ $t("vouchers.balanced") }}
                        </template>
                        <template v-else>
                            <i class="far fa-not-equal fa-fw"></i>
                            {{ $t("vouchers.unbalanced") }}
                        </template>
                    </div>
                </div>
                <div
                    class="col text-right text-bold"
                    :class="{
                        'text-warning': totalRemaining > 0,
                        'text-danger': totalRemaining < 0,
                        'text-primary': totalRemaining == 0
                    }"
                >
                    {{ $t("vouchers.remaining") }}:
                    {{
                        $n(totalRemaining, {
                            style: "currency",
                            currency: context.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </div>
            </div>
        </template>
        <template slot="header">
            <template v-if="edit">
                {{ $t("vouchers.edit_voucher_entry") }}
            </template>
            <template v-else>{{
                $t("vouchers.create_voucher_entry")
            }}</template>
        </template>

        <template slot="default">
            <div class="row voucher-data">
                <div class="col-5">
                    <div class="small text-muted">
                        {{ $t("vouchers.title") }}
                    </div>
                    {{ context.description }}
                </div>
                <div class="col">
                    <div class="small text-muted">
                        {{ $t("vouchers.date_and_yax_year") }}
                    </div>
                    {{ context.voucher_date }}
                    <div class="text-info small">
                        {{ $t("vouchers.tax_year") }}:
                        {{ context.voucher_tax_year }}
                    </div>
                </div>
                <div class="col">
                    <div class="small text-muted">
                        {{ $t("vouchers.ec_number") }}
                    </div>
                    {{ context.ec_voucher_number }}
                </div>
                <div class="col">
                    <div class="small text-muted">
                        {{ $t("vouchers.project_title") }}
                    </div>
                    {{ project.title }}
                </div>
            </div>

            <div class="mb-4 text-center">
                <base-button
                    size="sm mb-3"
                    v-for="m in milestones"
                    @click="addMilestoneAllocation(m)"
                    :type="m.id in entity ? 'danger' : 'primary'"
                    >M{{ m.m_ident }}</base-button
                >
            </div>

            <!-- Heading part with main amount -->
            <h1 class="row mb-4 mx-2" v-if="false">
                <div class="col">
                    {{ $t("vouchers.amount") }}:
                    {{
                        $n(context.amount, {
                            style: "currency",
                            currency: context.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </div>
                <div
                    class="col text-right"
                    :class="{
                        'text-warning': totalRemaining > 0,
                        'text-danger': totalRemaining < 0,
                        'text-primary': totalRemaining == 0
                    }"
                >
                    {{ $t("vouchers.remaining") }}:
                    {{
                        $n(totalRemaining, {
                            style: "currency",
                            currency: context.currency,
                            currencyDisplay: "code"
                        })
                    }}
                </div>
            </h1>
            <!-- End of heading part with amounts -->
            <template v-if="Object.keys(entity).length > 0">
                <div class="c-alloc" v-for="(ma, k) in entity" :key="k">
                    <div class="text-danger small mb-4 text-center">
                        <!-- REMOVE MILESTONE SECTION -->
                        <span @click="removeElement(k)" class="m-click">
                            <i class="far fa-trash "></i>
                            {{ $t("vouchers.remove_entry") }}</span
                        >
                        <hr class="mt-3 mb-1" />
                    </div>

                    <div
                        class="c-alloc__milestone"
                        :class="{
                            'has-error': hasGeneralError,
                            'is-balanced': isBalanced
                        }"
                    >
                        <div class="c-alloc__mname">
                            <!-- MILESTONE IDENT + TITLE -->
                            M{{ ma.project_milestone.m_ident }}:
                            {{ ma.project_milestone.name }}

                            <div class="small">
                                <span
                                    class="m-click text-danger"
                                    @click="zeroCats(ma.project_milestone.id)"
                                    ><i class="far fa-exclamation-triangle"></i>
                                    {{ $t("vouchers.zero_categories") }}</span
                                >
                            </div>
                        </div>

                        <div
                            class="c-alloc__amount"
                            :class="{
                                'text-danger': hasGeneralError,
                                'text-primary': !hasGeneralError
                            }"
                        >
                            <!-- Total remaining amount -->
                            <span
                                class="m-click mr-1"
                                @click="ma.amount = totalRemaining"
                            >
                                <i class="far fa-stamp"></i>
                            </span>
                            {{ $n(totalRemaining, "twodec") }}
                            <div class="small">
                                {{ $t("vouchers.total_remaining_amount") }}
                            </div>
                        </div>
                        <div
                            class="c-alloc__amount"
                            :class="{
                                'text-danger': ma.remaining_amount < 0,
                                'text-warning': ma.remaining_amount != 0,
                                'text-primary':
                                    ma.remaining_amount == 0 &&
                                    ma.amount != 0 &&
                                    ma.amount != ''
                            }"
                        >
                            <!-- Remaining amount in this milestone -->
                            {{ $n(ma.remaining_amount, "twodec") }}
                            <div class="small">
                                {{
                                    $t(
                                        "vouchers.remaining_amount_this_milestone"
                                    )
                                }}
                            </div>
                        </div>
                        <div class="c-alloc__amount">
                            <!-- How much is to be booked in this milestone INPUT -->
                            <v-cost-input
                                v-model="ma.amount"
                                @change="
                                    recalculateGroup(ma.project_milestone.id)
                                "
                            />
                            <div class="small">
                                {{
                                    $t(
                                        "vouchers.amount_to_distribute_this_milestone"
                                    )
                                }}
                            </div>
                        </div>
                    </div>

                    <!-- All categories -->
                    <div class="row">
                        <div
                            class="col-4"
                            v-for="(col_categories,
                            col_index) in columnizedCategories"
                        >
                            <div
                                v-for="ca in col_categories"
                                :key="ca.id"
                                class="c-cats"
                                :class="
                                    parseFloat(
                                        entity[ma.project_milestone.id][
                                            'category_allocations'
                                        ][ca.value]
                                    ) > 0
                                        ? 'c-cats__nonzero'
                                        : 'c-cats__zero'
                                "
                            >
                                <div
                                    class="c-cats__label"
                                    :class="{
                                        'c-cats__label-cost':
                                            ca.category_function === 1,
                                        'c-cats__label-noncost':
                                            ca.category_function === 2
                                    }"
                                >
                                    {{ ca.label }}
                                    <div v-if="false">
                                        <!-- v-model debugging -->
                                        {{
                                            entity[ma.project_milestone.id][
                                                "category_allocations"
                                            ][ca.value]
                                        }}
                                    </div>
                                </div>
                                <div class="c-cats__input">
                                    <v-cost-input
                                        @change="
                                            recalculateGroup(
                                                ma.project_milestone.id
                                            )
                                        "
                                        v-model="
                                            entity[ma.project_milestone.id][
                                                'category_allocations'
                                            ][ca.value]
                                        "
                                    /><button
                                        class="btn btn-sm text-primary"
                                        @click="
                                            entity[ma.project_milestone.id][
                                                'category_allocations'
                                            ][ca.value] = ma.remaining_amount
                                        "
                                    >
                                        <i class="far fa-stamp"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right mt-4">
                    <base-button
                        type="secondary"
                        size="lg"
                        :outline="true"
                        @click="$emit('close')"
                        >{{ $t("cancel") }}</base-button
                    >
                    <base-button
                        @click="saveForm"
                        type="primary"
                        size="lg"
                        :disabled="hasGeneralError || !isBalanced"
                        v-if="edit"
                        ><i class="far fa-save"></i>
                        {{ $t("edit") }}</base-button
                    >
                    <base-button
                        @click="saveForm"
                        :disabled="hasGeneralError || !isBalanced"
                        type="primary"
                        size="lg"
                        v-else
                        ><i class="far fa-plus"></i>
                        {{ $t("create") }}</base-button
                    >
                </div>
            </template>
            <template v-else>
                <div class="alert alert-warning alert-outline">
                    {{ $t("vouchers.no_bookkeeping_and_distribution_data") }}
                </div>
            </template>

            <pre v-if="false">{{ entity }}</pre>
        </template>
    </modal>
</template>

<style lang="scss">
.voucher-data {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid $gray-200;
}
.c-alloc {
    border: 1px solid $gray-500;
    border-radius: 6px;
    margin-bottom: 20px;
    padding: 12px 28px 28px 28px;

    &__milestone {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
        color: $gray-800;
        border-bottom: 1px solid $gray-600;

        &.has-error {
            border: 2px dotted $danger;
            padding: 10px;
            border-radius: 6px;
        }
    }

    &__mname {
        flex-grow: 1;
    }

    &__amount {
        flex: 0 0 300px;
        text-align: right;
        padding-left: 10px;
        padding-right: 8px;

        input {
            border: 1px solid $gray-500;
            border-radius: 6px;
            height: 25px;
            width: 200px;
        }
    }

    .c-cats {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;

        &.c-cats__nonzero {
            font-weight: 600;
            input {
                font-weight: 600;
            }
        }

        &.c-cats__zero {
            color: $gray-600;
            input {
                color: $gray-600;
            }
        }

        &__label {
            font-size: 13px;
            flex-grow: 1;
            border-bottom: 1px dotted $gray-600;
            margin-right: 10px;

            &-cost {
            }

            &-noncost {
                color: $warning;
                font-weight: bold;
            }
        }

        &__input {
            flex: 0 0 140px;
            input {
                border: 1px solid $gray-500;
                font-size: 12px;
                padding-left: 5px;
                border-radius: 6px;
                width: 100px;
                height: 24px;
            }

            button {
                margin-left: 5px;
            }
        }
    }
}
</style>

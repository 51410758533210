<script>
export default {
    props: {
        context: {}
    },
    computed: {
        text() {
            if (!this.context.is_manual) {
                return this.context.ec_connection.name;
            } else {
                return this.$t("vouchers.manual_import");
            }
        },
        css() {
            if (!this.context.is_manual) {
                return "";
            } else {
                return "text-warning";
            }
        }
    }
};
</script>

<template>
    <span :class="css">{{ text }}</span>
</template>
